import React from 'react';
import { css } from 'emotion';
import { rgba } from 'polished';
import PropTypes from 'prop-types';

import { themeVars } from '../../config/styles';

const TextArea = (props) => {
  const cssTextArea = css`
    transition: all 0.3s ease-in-out;
    padding: 20px 30px;
    font-size: 1.8rem;
    font-family: ${themeVars.root.headerFontFamily};
    background-color: ${rgba('#fff', 0.15)};
    border: 1px solid ${rgba('#fff', 0)};
    color: #fff;
    outline: none;
    width: 100%;
    margin-bottom: 40px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box;

    &:disabled {
      cursor: no-drop;
      opacity: 0.5;
    }

    &:focus {
      border-color: ${rgba('#fff', 1)};
    }

    & + p {
      margin-top: -2rem;
      font-size: 1.4rem;
    }

    ::-webkit-input-placeholder {
      color: ${rgba('#fff', 0.9)};
    }

    :-ms-input-placeholder {
      color: ${rgba('#fff', 0.9)};
    }

    ::placeholder {
      color: ${rgba('#fff', 0.9)};
    }
  `;
  return <textarea className={cssTextArea} type="text" {...props} rows="10" />;
};

TextArea.defaultProps = {
  disabled: false,
};

TextArea.propTypes = {
  /** Disabled input */
  disabled: PropTypes.bool,
};

export default TextArea;

import React from 'react';
import { css } from 'emotion';
import { rgba } from 'polished';
import PropTypes from 'prop-types';

import { themeVars } from '../../config/styles';
import { breakup } from '../../config/breakpoints';

const Input = (props) => {
  const cssInput = css`
    transition: all 0.3s ease-in-out;
    background-color: ${rgba('#fff', 0.15)};
    border: 1px solid ${rgba('#fff', 0)};
    color: #fff;
    font-family: ${themeVars.root.headerFontFamily};
    font-size: 1.8rem;
    max-width: 100%;
    outline: none;
    padding: 20px 30px;
    width: 100%;
    margin-bottom: 2rem;

    ${breakup.medium`margin-bottom: 3rem;`}
    ${breakup.large`margin-bottom: 4rem;`}

    &:disabled {
      cursor: no-drop;
      opacity: 0.5;
    }

    &:focus {
      border-color: ${rgba('#fff', 1)};
    }

    & + p {
      margin-top: -2rem;
      font-size: 1.4rem;
    }

    ::-webkit-input-placeholder {
      color: ${rgba('#fff', 0.9)};
    }

    :-ms-input-placeholder {
      color: ${rgba('#fff', 0.9)};
    }

    ::placeholder {
      color: ${rgba('#fff', 0.9)};
    }
  `;
  return <input className={cssInput} {...props} />;
};

Input.defaultProps = {
  disabled: false,
};

Input.propTypes = {
  /** Disabled input */
  disabled: PropTypes.bool,
};

export default Input;

import React, { useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { css } from 'emotion';
import { Formik } from 'formik';
import { Row, Col } from 'react-grid-system';
import * as Yup from 'yup';
import axios from 'axios';

import { breakup } from '../../config/breakpoints';

import ButtonLight from '../Common/ButtonLight';
import Input from '../Form/Input';
import TextArea from '../Form/TextArea';

const ContactForm = () => {
  const [submit, setSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  // Enconde the form values for use with Netlify forms
  const encode = (formValues) =>
    Object.keys(formValues)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(formValues[key])}`)
      .join('&');

  // Define the validation schema for the form - https://github.com/jquense/yup
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Please enter your first name'),
    lastName: Yup.string().required('Please enter your last name'),
    company: Yup.string().required('Please enter your company name'),
    email: Yup.string()
      .email('Please enter a valid email address')
      .required('Please enter your email address'),
    phone: Yup.number('Enter a valid phone number')
      .required('Please enter your phone number')
      .typeError('Please enter a valid number'),
    message: Yup.string('Enter a valid message').required('Please enter a message'),
  });

  const cssForm = css`
    margin-top: 4rem;
  `;

  const cssSendError = css`
    margin: 50px 0;
    color: #fff;
  `;

  const cssError = css`
    color: #ff2727;
    margin-top: -1rem;
    margin-bottom: 3rem;
    font-size: 1.4rem;
    font-weight: 600;

    ${breakup.large`margin-top:-3rem;`}
  `;

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        company: '',
        email: '',
        phone: '',
        message: '',
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setIsLoading(true);

        await axios
          .post('/', encode({ 'form-name': 'contact', ...values }), {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          })
          .then((res) => {
            if (res.status === 200) {
              setSubmit(true);
              setSubmitting(false);
              setIsLoading(false);
              window.gtag('event', 'click', {
                event_category: 'enquiry',
                event_label: 'contact form',
              });
            }
          })
          .catch(() => {
            setSubmitting(false);
            setIsLoading(false);
            setIsError(true);
          });
      }}
      render={({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => {
        if (isLoading) {
          return <CircularProgress style={{ color: '#FFF', margin: '50px 0' }} />;
        }

        if (isError) {
          return <p className={cssSendError}>There was a problem. Please try again later.</p>;
        }

        return (
          <>
            {!submit ? (
              <form name="contact" className={cssForm} onSubmit={handleSubmit} netlify="true">
                <Row>
                  <Col md={6}>
                    <label aria-label="First name" aria-required="true" htmlFor="firstName">
                      <Input
                        name="firstName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="First name"
                        type="text"
                        value={values.firstName}
                      />
                    </label>

                    {errors.firstName && touched.firstName && (
                      <p className={cssError}>{errors.firstName}</p>
                    )}
                  </Col>
                  <Col md={6}>
                    <label aria-label="Last name" aria-required="true" htmlFor="lastName">
                      <Input
                        name="lastName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Last name"
                        type="text"
                        value={values.lastName}
                      />
                    </label>

                    {errors.lastName && touched.lastName && (
                      <p className={cssError}>{errors.lastName}</p>
                    )}
                  </Col>

                  <Col md={12}>
                    <label aria-label="Company" aria-required="false" htmlFor="company">
                      <Input
                        name="company"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Company"
                        type="text"
                        value={values.company}
                      />
                    </label>

                    {errors.company && touched.company && (
                      <p className={cssError}>{errors.company}</p>
                    )}
                  </Col>

                  <Col md={6}>
                    <label aria-label="Email address" aria-required="true">
                      <Input
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Email"
                        type="email"
                        value={values.email}
                      />
                    </label>

                    {errors.email && touched.email && <p className={cssError}>{errors.email}</p>}
                  </Col>

                  <Col md={6}>
                    <label aria-label="Phone number" aria-required="false">
                      <Input
                        name="phone"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Phone"
                        type="text"
                        value={values.phone}
                      />
                    </label>

                    {errors.phone && touched.phone && <p className={cssError}>{errors.phone}</p>}
                  </Col>

                  <Col md={12}>
                    <label aria-label="Your message" aria-required="true">
                      <TextArea
                        name="message"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Message"
                        type="text"
                        value={values.message}
                      />
                    </label>

                    {errors.message && touched.message && (
                      <p className={cssError}>{errors.message}</p>
                    )}
                  </Col>
                </Row>

                <ButtonLight type="submit" variant="outlined">
                  Submit
                </ButtonLight>
              </form>
            ) : (
              <p className={cssSendError}>
                We have received your message and will be in touch shortly
              </p>
            )}
          </>
        );
      }}
    />
  );
};

export default ContactForm;
